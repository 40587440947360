@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;600&display=swap');

body {
  background-color: #110E0F;
  color: white;
  font-family: 'Quicksand', sans-serif;
  padding: 0;
  margin: 0;
}

.title {
  width: 80vw;
  margin: auto;
}