.project {
    background-color: pink;
    z-index: -1;
}

#sectionPin {
    height: 100vh;
    overflow: hidden;
    display: flex;
    left: 0;
    background: var(--text-color);
    color: var(--bg-color);
  }


.pin-wrap {
    height: 100vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 50px 10vw;
  
    & > * {
      min-width: 60vw;
      padding: 0 5vw;
    }
  }
  